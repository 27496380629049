import React, {Component} from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import CurrencyFormat from 'react-currency-format';
import {API_URL} from '../../config/baseUrl';
import axios from 'axios';
import moment from 'moment';

import './TransactionDetail.css';

export default class TransactionDetail extends Component {

   state = {
      transactionData: {},
      loading: true,
      isDialogOpen: false,
      dialogKey: '',
      colorBG: '',
      amount: 0,
      activityLoading: false
   }

   componentDidMount(){
      //.log(this.props.match.params.id)
     const USER_TOKEN = localStorage.getItem('token');
       const AuthStr = 'Bearer '.concat(USER_TOKEN); 
       let config = {
        headers: { Authorization: AuthStr },
        params: {
          id: this.props.match.params.id
        },
      }
      
       axios.get(API_URL + '/admin/retrieve-transaction-details', config)
       .then(response => {
           if (response.data.message === "success") {
              ////.log(response.data);
              this.setState({transactionData: response.data.transactionData, loading:false})
           }

           else if(response.data.message == "blocked") {
               this.logOut()
           }
       })
       .catch((error) => {
           //.log('error ' + error);
       });
  }


  reverseTransactionFunc = (route) => {
     const isConfirmed = window.confirm(route == "revert-transaction" ? "Do you want to reverse this transaction?" :
      "Have you confirmed transaction was successful?");
     if(!isConfirmed) return null;
     this.setState({activityLoading: true})
     const USER_TOKEN = localStorage.getItem('token');
     var config = {
        headers: {'Authorization': "bearer " + USER_TOKEN}
     };
     
     var bodyParameters = {
         TRANS_ID: this.state.transactionData.TRANS_ID
     }
     
     axios.post(API_URL + 
        `/admin/${route}`,
        bodyParameters,
        config
        ).then((response) => {
           if (response.data.message === "success") {
              ////.log(response.data);
              window.location.reload();
           }

           else if (response.data.message === "blocked") {
              this.logOut()
           }
        }).catch((error) => {
           //.log(error)
           this.setState({activityLoading: false})
           alert("network error")
     });
    
  }

  logOut = () => {
     localStorage.clear();
      window.location.reload();
   }


  openDialog = (dialogKey, colorBG) => {
     this.setState({ isDialogOpen: true, dialogKey, colorBG })
  }

   handleClose = () => this.setState({ isDialogOpen: false })


    render() {
       const {transactionData, dialogKey, isDialogOpen, loading, colorBG} = this.state;
       const adminData =  JSON.parse(localStorage.getItem("userData"));
        return (
            loading ? 
            <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
               <Spinner color="#47c494" size={30}/>   
            </div>          
            :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Transaction detail</p>
                    {adminData.level == "Super admin" &&
                     transactionData.status == "awaiting" ?
                     <div style={{display: 'flex', flexDirection:'row'}}>
                         <button onClick={() => this.reverseTransactionFunc('revert-transaction')}
                           className="reverse-trans-btn">REVERSE TRANSACTION
                         </button>
                         <button 
                           style={{background: '#2D61CF', marginLeft: 20}}
                           onClick={() => this.reverseTransactionFunc('update-transaction-status-sucesss')}
                           className="reverse-trans-btn">UPDATE TRANSACTION
                          </button>
                     </div>
                     : ""
                   }
                </div>

                <div className="details-container">

                {isDialogOpen &&
                     <div className="dialog-box-container">
                     <p><span style={{color: colorBG}}>{  dialogKey + "  " }</span> TRANS-ID <span style={{color:'#a4dcb9', fontSize:20, fontWeight:600}}>{ "   " + transactionData.TRANS_ID }</span></p>
   
                    </div>
                   }

                    {transactionData.transaction_type === "transfer" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Transaction Type</p>
                          <p className="info-response">{transactionData.transaction_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status === "success" ? "completed" : transactionData.status}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Transfer Type</p>
                          <p className="info-response">{transactionData.transfer_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Sender Account Number</p>
                          <p className="info-response">{transactionData.sender_account_number}</p>
                       </div>
                       

                       <div className="user-data-info">
                          <p className="info-title">Receiver Account Number</p>
                          <p className="info-response">{transactionData.receiver_account_number}</p>
                       </div>

                       {transactionData.product_name &&
                        <div className="user-data-info">
                          <p className="info-title">Receipient</p>
                          <p className="info-response">{transactionData.product_name}</p>
                       </div>
                       }

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Payment Source Type</p>
                          <p className="info-response">{transactionData.payment_source_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.date_initiated).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                       { transactionData.unique_transfer_code &&
                          <div className="user-data-info">
                          <p className="info-title">Unique Transfer Code</p>
                          <p className="info-response">{transactionData.unique_transfer_code}</p>
                       </div>
                       }

                    </div>
                    }

                    {transactionData.transaction_type === "foreign-transfer" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Transaction Type</p>
                          <p className="info-response">{transactionData.transaction_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status === "success" ? "completed" : transactionData.status}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Transfer Type</p>
                          <p className="info-response">{transactionData.transfer_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Sender Account Number</p>
                          <p className="info-response">{transactionData.sender_account_number}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Country</p>
                          <p className="info-response">{transactionData.foreignTx_country + " Bank Transfer"}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Beneficiary Bank</p>
                          <p className="info-response">{transactionData.foreignTx_bank}</p>
                       </div>
                       

                       <div className="user-data-info">
                          <p className="info-title">Receiver Account Number</p>
                          <p className="info-response">{transactionData.receiver_account_number}</p>
                       </div>

                       {transactionData.product_name &&
                        <div className="user-data-info">
                          <p className="info-title">Receipient</p>
                          <p className="info-response">{transactionData.product_name}</p>
                       </div>
                       }

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                            suffix={"  " + transactionData.foreignTx_code} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Payment Source Type</p>
                          <p className="info-response">{transactionData.payment_source_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.date_initiated).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                       { transactionData.unique_transfer_code &&
                          <div className="user-data-info">
                          <p className="info-title">Unique Transfer Code</p>
                          <p className="info-response">{transactionData.unique_transfer_code}</p>
                       </div>
                       }

                    </div>
                    }


                    {transactionData.transaction_type === "topup" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Transaction Type</p>
                          <p className="info-response">{transactionData.transaction_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status === "success" ? "completed" : transactionData.status}</p>
                       </div>

                       
                       <div className="user-data-info">
                          <p className="info-title">User ID String</p>
                          <p className="info-response">{transactionData.user_id}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Account number</p>
                          <p className="info-response">{transactionData.sender_account_number}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Payment Source Type</p>
                          <p className="info-response">{transactionData.payment_source_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.date_initiated).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                    </div>
                    }

                    {transactionData.transaction_type === "withdrawal" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Transaction Type</p>
                          <p className="info-response">{transactionData.transaction_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status === "success" ? "completed" : transactionData.status}</p>
                       </div>

                       
                       <div className="user-data-info">
                          <p className="info-title">User ID String</p>
                          <p className="info-response">{transactionData.user_id}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Account Number</p>
                          <p className="info-response">{transactionData.account_number}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Payment Source Type</p>
                          <p className="info-response">{transactionData.payment_source_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Bank Account Number</p>
                          <p className="info-response">{transactionData.bank_account_number}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Unique Transfer Code</p>
                          <p className="info-response">{transactionData.unique_transfer_code}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.date_initiated).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                    </div>
                    }

                    {transactionData.transaction_type === "bills" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Transaction Type</p>
                          <p className="info-response">{transactionData.transaction_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status === "success" ? "completed" : transactionData.status}</p>
                       </div>

                       
                       <div className="user-data-info">
                          <p className="info-title">User ID String</p>
                          <p className="info-response">{transactionData.user_id}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Account Number</p>
                          <p className="info-response">{transactionData.account_number}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Payment Source Type</p>
                          <p className="info-response">{transactionData.payment_source_type}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Bill Tag</p>
                          <p className="info-response">{transactionData.bill_tag}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Bill Category</p>
                          <p className="info-response">{transactionData.bill_category}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Receipient Number</p>
                          <p className="info-response">{transactionData.receipient_number}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.date_initiated).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                    </div>
                    }

                   <div className="user-identity-card-wrapper">
                      
                   </div>
                </div>

            </div>
               
        )
    }

}
