import React, {Component} from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import CurrencyFormat from 'react-currency-format';
import {API_URL} from '../../config/baseUrl';
import axios from 'axios';
import moment from 'moment';


import './Analytics.css';

const REFERENCE = moment();
const TODAY = REFERENCE.clone().startOf('day');

export default class Analytics extends Component {

   state = {
      loading: true,
      totalUsers: 0,
      verifiedUsers: 0,
      totalTransactions: 0,
      usersToday: 0,
      transactionsValue: 0,
      transactionsToday: 0,
      verifiedUsers: 0,
      transactionsMonthValue: 0,
      transactionsWeekValue: 0,
      totalSaversValue: 0,
      totalSavers: 0,
      failedTransactions: 0,
      transactionsTodayValue: 0
   }

   componentDidMount(){
       this.getAllUserData();
       this.getAllTransactionData();
   }

   getAllUserData = async () => {
      const USER_TOKEN = localStorage.getItem('token');
      const AuthStr = 'Bearer '.concat(USER_TOKEN); 
      const response = await axios.get(API_URL + '/admin/all-user-data', { headers: { Authorization: AuthStr } });
      const verifiedUser = response.data.users.filter(user => user.verified);
      const usersToday = response.data.users.filter(user => this.isToday(user.date_created))
      this.setState({
          totalUsers: response.data.users.length,
          verifiedUsers: verifiedUser.length,
          usersToday: usersToday.length,
          loading: false
         })
      //console.log("users", response.data)
   }

   getAllTransactionData = async () => {
      const USER_TOKEN = localStorage.getItem('token');
      const AuthStr = 'Bearer '.concat(USER_TOKEN); 
      let transactionsValue = 0;
      let transactionsWeekValue = 0;
      let transactionsMonthValue = 0;
      let transactionsTodayValue = 0;
      const response = await axios.get(API_URL + '/admin/all-transaction-data', { headers: { Authorization: AuthStr } });
      const transactions = response.data.transactions.filter(trans => (trans.status == "success" || trans.status == "completed")
       && trans.transfer_type != "IN-COMING");

      transactions.forEach(trans => {
         transactionsValue += trans.amount;
      });
      const transactionsToday = transactions.filter(transactions => this.isToday(transactions.date_initiated));
      const transactionsWeek =  transactions.filter(transactions => this.isWeek(transactions.date_initiated));
      const transactionsMonth =  transactions.filter(transactions => this.isMonth(transactions.date_initiated));
      
      transactionsToday.forEach(trans => {
         transactionsTodayValue += trans.amount;
      });

      transactionsWeek.forEach(trans => {
         transactionsWeekValue += trans.amount;
      });

      transactionsMonth.forEach(trans => {
         transactionsMonthValue += trans.amount;
      });

      this.setState({
          transactionsValue,
          totalTransactions: transactions.length,
          transactionsToday: transactionsToday.length,
          transactionsWeekValue,
          transactionsMonthValue,
          transactionsTodayValue
         })
      //console.log("transactions", response.transactions)
   }

  isToday = (momentDate) => {
      //console.log(momentDate)
      return moment().isSame(momentDate, 'day'); 
  }

  isWeek= (momentDate) => {
   //console.log(momentDate)
   return moment().isSame(momentDate, 'week'); 
}

  isMonth = (momentDate) => {
   //console.log(momentDate)
   return moment().isSame(momentDate, 'month'); 
}
 
  render() {
        const {
            loading, 
            totalUsers, 
            verifiedUsers, 
            usersToday, 
            transactionsValue, 
            totalTransactions, 
            transactionsToday,
            transactionsMonthValue,
            transactionsWeekValue,
            transactionsTodayValue
         } = this.state;
        
         return (
            loading ? 
            <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
               <Spinner color="#f33975" size={30}/>  
               <p style={{color: '#1a2d6e', fontSize: 12, marginLeft: -17, marginTop: 12}}>Analyzing datas...</p> 
            </div>          
            :
            <div className="main-content-wrapper">
               <p style={{fontSize:13, color: '#1a2d6e', marginBottom: 10, marginTop: 50}}>Users Data</p>
                <div className="users-analytic-wrapper">
                   <div className="stat-box-wrap">
                        <p>Total users</p>
                        <h1>{totalUsers}</h1>
                   </div>

                   <div className="stat-box-wrap" style={{backgroundColor: '#d8edfd'}}>
                        <p>Verified users</p>
                        <h1>{verifiedUsers}</h1>
                   </div>

                   <div className="stat-box-wrap" style={{backgroundColor: '#fef9e6', color: '#1a2d6e'}}>
                        <p>users TODAY</p>
                        <h1>{usersToday}</h1>
                   </div>

                </div>

                <p style={{fontSize:13, color: '#1a2d6e', marginBottom: 10, marginTop: 50}}>Transactions Data</p>
                <div className="users-analytic-wrapper">
                   <div className="stat-box-wrap" style={{backgroundColor:"#e9f7e7"}}>
                        <p>Total transaction Value</p>
                        <CurrencyFormat value={transactionsValue.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h1>{value}</h1>} />
                   </div>

                   <div className="stat-box-wrap" style={{backgroundColor:"#dfd8f7"}}>
                        <p>transaction Value THIS MONTH</p>
                        <CurrencyFormat value={transactionsMonthValue.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h1>{value}</h1>} />
                   </div>

                   <div className="stat-box-wrap" style={{backgroundColor:"#f2f2f3"}}>
                        <p>transaction Value THIS WEEK</p>
                        <CurrencyFormat value={transactionsWeekValue.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h1>{value}</h1>} />
                   </div>

                   <div className="stat-box-wrap" style={{backgroundColor:"#ededcf"}}>
                        <p>transaction Value TODAY</p>
                        <CurrencyFormat value={transactionsTodayValue.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => <h1>{value}</h1>} />
                   </div>

                   <div className="stat-box-wrap" style={{backgroundColor: '#f1eef2'}}>
                        <p>Total Number of Transactions</p>
                        <h1>{totalTransactions}</h1>
                   </div>

                   <div className="stat-box-wrap" style={{backgroundColor: '#feeaea'}}>
                        <p>transactions TODAY</p>
                        <h1>{transactionsToday}</h1>
                   </div>

                </div>
                
            </div>
               
        )
    }

}
