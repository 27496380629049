import React, {Component} from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import {API_URL} from '../../config/baseUrl';
import axios from 'axios';
import moment from 'moment';
import CustomSelect from '../CustomSelect/CustomSelect';
import CurrencyFormat from 'react-currency-format';
import './Transactions.css';


export default class Transactions extends Component {

    state = {
        loading: true,
        transactionsList: [],
        awaitingTransactionsCount: 0,
        allTransactionList: [],
        queryString: '',
        transactionsLoading: false,
        showIncoming: false,
        showAllTransactions: false,
        showAwaitingTransactions: false,
        isOpen: false,
        selectedData: {value: 'allTime', label: "All time users"}
     }

     componentWillMount(){
        this.getAllTransactions();
    
    }

    getAllTransactions = () => {
        const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        axios.get(API_URL + '/admin/get-all-transactions', { headers: { Authorization: AuthStr } })
        .then(response => {
            ////.log(response.data);
            const onlySuccessfulTrans = response.data.transactions.filter(trans => trans.status == "success" || trans.status == "completed");
            const onlyAwaitingTrans = response.data.transactions.filter(trans => trans.status == "awaiting");
            this.setState({
                transactionsList: this.state.showAllTransactions? response.data.transactions :  onlySuccessfulTrans, 
                allTransactionList: response.data.transactions, 
                awaitingTransactionsCount: onlyAwaitingTrans.length,
                loading:false
            })
        })
        .catch((error) => {
            //.log('error ' + error);
        });
    }

    showAllTransactionsFunc = () => {
        this.setState({
            loading: true,
            showAllTransactions: !this.state.showAllTransactions, showAwaitingTransactions: false}, () => this.getAllTransactions())
    }

    showAwaitingTransactionsFunc = () => {
        this.setState({
            loading: true,
            showAllTransactions: false, showAwaitingTransactions: !this.state.showAwaitingTransactions}, () => this.getAwaitingTransactions())
    }

    getAwaitingTransactions = () => {
        const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        axios.get(API_URL + '/admin/get-all-transactions', { headers: { Authorization: AuthStr } })
        .then(response => {
            ////.log(response.data);
            const onlyAwaitingTrans = response.data.transactions.filter(trans => trans.status == "awaiting");
            this.setState({
                transactionsList: !this.state.showAwaitingTransactions? response.data.transactions : onlyAwaitingTrans, 
                allTransactionList: response.data.transactions, 
                loading:false
            })
        })
        .catch((error) => {
            //.log('error ' + error);
        });
    }

    handleChange = (event) => {
        this.setState({queryString: event.target.value});
        if (event.target.value == '') {
            this.setState({transactionsList: this.state.allTransactionList});
        }
    }

    findUserTransactions = async () => {
        this.setState({transactionsLoading: true})
        const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        const {queryString} = this.state;
        const data  = {
            queryString,
        }
        try {
            const response = await axios.post(API_URL + '/admin/find-user-transactions', data, { headers: { Authorization: AuthStr } });
           // console.log(response)
            this.setState({transactionsList: response.data.transactions, transactionsLoading: false, showIncoming: true})
        } catch (error) {
            //console.log('error ' + error);
        }
       
    }

    searchUserList = async () => {
        const {queryString} = this.state;
         if (queryString != "") {
             const filteredList = []
             await this.state.allTransactionList.map(transaction => {
                if (queryString == transaction.TRANS_ID || 
                    queryString == transaction._id || 
                    queryString == transaction.sender_account_number ||
                    queryString == transaction.status 
                    ) {
                    filteredList.push(transaction)
                }
            })
           this.setState({transactionsList: filteredList})
         }
  
     }

     logOut = () => {
        localStorage.clear();
        window.location.reload();
      }

isInComingTransfer = (type) => {
    if(type == "IN-COMING")  return true;
    else return false;
}

showIncomingFunc = (trans) => {
    if(trans.transaction_type == "transfer" &&  trans.transfer_type == "IN-COMING") return false;
    else return true;
}

isOpenFunc = () => {
    this.setState({isOpen: !this.state.isOpen})
}

onSelectFunc = (item) => {
    this.setState({selectedData: item, isOpen: false})
}



 render() {

        const transactionsList = this.state.transactionsList.slice(0, 100).map(transaction => {

            return (
                    this.state.loading ? 
                    <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                        <Spinner color="#47c494" size={30}/>   
                    </div>          
                    :

                    <ul key={transaction._id} className="list-contents" 
                       style={{backgroundColor: this.isInComingTransfer(transaction.transfer_type) ? '#fcf5fb' : 'white',
                         }}>
                        <li className="list-items">
                            {transaction.transaction_type == "transfer" &&
                              <img className="list-items-icon" 
                              src={this.isInComingTransfer(transaction.transfer_type) ? "/down-icon-dodo.png" 
                              : transaction.payment_ref == 'bank_transfer' ? "bank-transfer-icon.png" : "/down-icon.png"} />
                             }
                             {transaction.transaction_type == "foreign-transfer" && 
                              <img className="list-items-icon" src="/up-icon2.png" />
                             }
                             {transaction.transaction_type == "dodo-lite-transfer" && 
                              <img className="list-items-icon" src="/up-icon2.png" />
                             }
                             {transaction.transaction_type == "topup" && 
                              <img className="list-items-icon" src="/add-funds.png" />
                             }
                             {transaction.transaction_type == "withdrawal" && 
                              <img style={{}}
                               className="list-items-icon" src="/withdraw.png" />
                             }
                             {transaction.transaction_type == "bills" && 
                              <img className="list-items-icon" src="/bills-payment2.png" />
                             }
                             
                        </li>
                        <li className="list-items">
                            {this.isInComingTransfer(transaction.transfer_type) ? "INBOUND-TRANSFER" 
                            : transaction.payment_ref == 'bank_transfer' ? "BANK TRANSFER" : transaction.transaction_type.toUpperCase()}
                        </li>
                        <li className="list-items">
                            {transaction.status}
                        </li>
                        <li className="list-items">
                            {moment(transaction.date_initiated).format('DD MMM YYYY')}
                        </li>
                        <li className="list-items">
                            {transaction.transaction_type == "foreign-transfer" ? 
                               <CurrencyFormat value={transaction.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                               suffix={"  " + transaction.foreignTx_code} />
                               :
                               <CurrencyFormat value={transaction.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                               prefix={'#'} suffix={""} />
                            }
                        </li>
                        <li className="list-items">
                            <Link to={"/transactions-detail/" + transaction._id}>
                                <button className="trans-view-button">
                                    View
                                </button>
                            </Link>
                        </li>
                </ul>
            )
        }) 

        return (
            this.state.loading ? 
              <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                  <Spinner color="#47c494" size={30}/>   
              </div> 
              :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Transactions</p>
                </div>
                   
                <div className="row-title" >
                    <p style={{fontWeight:500}}>All Transactions</p>
                    <div className="search-input-wrapper">
                        
                        <div className="show-all-wrap">
                            <p>Show all</p>
                            <span onClick={this.showAllTransactionsFunc}
                            style={{backgroundColor: this.state.showAllTransactions ? '#6fcb7d' : 'transparent'}}></span>
                        </div>
                        <div className="show-all-wrap">
                            <p className="awaitingTransactionsCount">{this.state.awaitingTransactionsCount}</p>
                            <p>Awaiting Transactions</p>
                            <span onClick={this.showAwaitingTransactionsFunc}
                            style={{backgroundColor: this.state.showAwaitingTransactions ? '#6fcb7d' : 'transparent'}}></span>
                        </div>
                    <input type="text" name="queryString" value={this.state.queryString} placeholder="search" onChange={this.handleChange} />
                    <img src="/search-icon.png" onClick={this.findUserTransactions} />
                    </div>
                </div>

                <div className="list-container" >

                    <ul className="list-contents-title">
                        <li className="list-items-title">
                            Icon
                        </li>
                        <li className="list-items-title">
                             Type
                        </li>
                        <li className="list-items-title">
                            Status
                        </li>
                        <li className="list-items-title">
                            Time
                        </li>
                        <li className="list-items-title">
                            Amount
                        </li>
                        <li className="list-items-title">
                            View Details
                        </li>
                    </ul>
                     
                     {this.state.transactionsLoading ?
                       <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                         <Spinner color="#47c494" size={20}/>   
                        </div> 
                        :
                      transactionsList
                     }
                    
                </div>

            </div>
               
        )
    }

}
