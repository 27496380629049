import React, {Component} from 'react';
import {Link} from "react-router-dom";
import './Header.css';
import {API_URL} from '../../config/baseUrl';
import axios from 'axios';

export default class Header extends Component {
  
  state = {
     ghanaRate: 0,
     southAfricaRate: 0,
     usRate: 0,
     loading: true,
  }

  componentWillMount(){
    this.updateRates();
  }

  updateRates = async () => {
    const ghanaRate = await this.getTransferRates('GHS');
    const southAfricaRate = await this.getTransferRates('ZAR');
    //const usRate = await this.getTransferRates('USD');
    this.setState({southAfricaRate, ghanaRate, loading: false});
    //console.log(usRate)
  }

  getTransferRates = async (countryCode) => {
     const data = {amount: 1, countryCode}
     try {
       const response = await axios.post(API_URL + '/admin/get-transfer-rates', data);
       //console.log(response.data.transferData.rate);
       return response.data.transferData.rate;
     } catch (error) {
       console.log('error rates', error);
       return 0;
     }
  }



    render() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        return (
               <div className="header-wrapper">
                  <div className="logo-wrapper">
                    <Link to="/"><img className="logo-image" src="/dodo-blue.png" /></Link>
                  </div>

                  <div className="header-right-side-wrapper">
                    <div className="transfer-rates-wrapper">
                        <p style={{fontWeight:600, color:'#4a8be0', fontSize:13}}>Transfer Rates</p>
                        { this.state.loading ?
                          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                          :
                          <div>
                            <p style={{color:'#56c05d', fontSize:10, fontWeight:500,}}>GHS - #{this.state.ghanaRate.toFixed(2)}</p>
                            <p style={{color:'#f1668b', fontSize:10, fontWeight:600,}}>ZAR - #{this.state.southAfricaRate.toFixed(2)}</p>
                          </div>
                        }
                     </div>
                     <div className="account-info-wrapper">
                        <p style={{fontWeight:600, color:'#f33975', fontSize:13}}>{userData.name}</p>
                        <p style={{color:'#677cc1', fontSize:10}}>{userData.level}</p>
                        <p style={{color:'#677cc1', fontSize:10}}>ID No. {userData.account_no}</p>
                     </div>
                  </div>
               </div>
        )
    }

}
