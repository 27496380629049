import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import axios from 'axios';
import Login from '../Login/Login';
import {API_URL} from '../../config/baseUrl';
import CustomSelect from '../CustomSelect/CustomSelect';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import moment from 'moment';

import './Savings.css';


var REFERENCE = moment();
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

export default class Savings extends Component {

state = {
    loading: true,
    allUsersList: [],
    usersList: [],
    queryString: '',
    isLoggedIn: true,
    usersCount: 0,
    transactionsCount: 0,
    verifiedUserCount: 0,
    searchLoading: false,
    isOpen: false,
    loadLimit: 100,
    isSuccess: false,
    isLoadingEvent: false,
    isDialogOpen: false,
    savingData: {account_number: '000000000000000000', amount: 4500},

}

componentWillMount(){   
    const USER_TOKEN = localStorage.getItem('token');
    const AuthStr = 'Bearer '.concat(USER_TOKEN);

    this.getSavingsData();

    //GET TRANSACTIONS COUNTS
    axios.get(API_URL + '/admin/get-all-transactions', { headers: { Authorization: AuthStr } })
    .then(response => {
        ////.log(response.data);
        this.setState({transactionsCount: response.data.transactions.length})
    })
    .catch((error) => {
        this.logOut();
        //.log('error ' + error);
    });
}

getSavingsData = async () => {
    const USER_TOKEN = localStorage.getItem('token');
    const AuthStr = 'Bearer '.concat(USER_TOKEN); 
    try {
        const response = await axios.get(API_URL + '/admin/get-all-savings-data', { params: {
                limit: this.state.loadLimit
            }, headers: { Authorization: AuthStr } });
            //console.log('savings', response.data.savingsArray)
        
        this.setState({
            usersList: response.data.savingsArray, 
            loading:false, 
        })
    } catch (error) {
        //this.logOut()
        console.log('error ' + error);
    }
    
}




logOut = () => {
localStorage.clear();
    window.location.reload();
}

    

isOpenFunc = () => {
    this.setState({isOpen: !this.state.isOpen})
}

checkIfSavingsStatus = (status) => {
    if(status == "Closing") return true;
    else return false;
}


//COMPUTE EARNINGS PER DAY FOR ALL SAVINGS
calculateEarnings = (savingData) => {
  if(savingData.status !== "Active") return savingData.closed_earnings.toFixed(2);
  const diffDays = moment().diff(moment(savingData.start_date), 'days');
  const dailyInterest = savingData.interest_rate / 365;
  const earnings = (dailyInterest / 100) * savingData.amount * diffDays;
  return earnings.toFixed(2);
}

daysCount = (savingData) => {
  if(savingData.status == "Closed" || savingData.status == "Closing"){
    const diffDays = moment(savingData.closed_date).diff(moment(savingData.start_date), 'days');
    return parseInt(diffDays)
  }else {
    const diffDays = moment().diff(moment(savingData.start_date), 'days');
    return parseInt(diffDays)
  }
}

openDialog = (savingData) => {
    const adminData =  JSON.parse(localStorage.getItem("userData"));
    if(adminData.level != "Super admin") return alert("You need Level 1 access for this feature.")
    this.setState({ savingData, isDialogOpen: true})
}

handleClose = () => this.setState({ isDialogOpen: false })

//PROCESS CLOSING SAVINGS
processSavingsWithdraw = (savingData) => {
      const adminData =  JSON.parse(localStorage.getItem("userData"));
      if(adminData.level != "Super admin") return null;
      this.setState({isDialogOpen: true, isLoadingEvent:  true})
      const USER_TOKEN = localStorage.getItem('token');
      var config = {
         headers: {'Authorization': "bearer " + USER_TOKEN}
      };
      
      var bodyParameters = {
         savingID: savingData._id,
         account_number: savingData.account_number
      }
      
      axios.post(API_URL +  
         '/admin/process-savings-withdraw',
         bodyParameters,
         config
         ).then((response) => {
            if (response.data.message === "success") {
               this.setState({isSuccess: true, isLoadingEvent: false});
               setTimeout(() => {
                  window.location.reload();
               }, 1000);
            }

            else if (response.data.message === "blocked") {
                this.logOut()
            }
            else {
                this.setState({isSuccess: false, isLoadingEvent: false,})
                alert(response.data.message);
            }
         }).catch((error) => {
            alert("network error");
            this.setState({isSuccess: false, isLoadingEvent: false})
      });
     
   }

render() {
        const {loading, isDialogOpen, isSuccess, isLoadingEvent, savingData} = this.state;
        const closingSavings = this.state.usersList.sort(function(x, y){
                return new Date(y.start_date) - new Date(x.start_date);
            }).map(saving => {
            return (
                    saving.status == "Closing" &&
                    <ul key={saving._id} className="list-contents">
                        <li className="list-items">
                            {saving.account_number}
                        </li>
                        <li className="list-items" style={{paddingLeft:40}}>
                            #{saving.amount.toFixed(2)}
                        </li>
                        <li className="list-items" style={{paddingLeft:30}}>
                            <span style={{
                                backgroundColor: '#ddf2ff',
                                color: '#08227b',
                                padding: 10,
                                fontWeight: 600,
                                borderRadius: 5
                                }}>
                                {saving.status}
                            </span>
                        </li>
                        <li className="list-items">
                            #{this.calculateEarnings(saving)}
                        </li>
                        <li className="list-items" style={{width: 150}}>
                            {this.daysCount(saving)}
                        </li>
                        <li className="list-items">
                              <Link style={{}}>
                                <button 
                                  onClick={() => this.openDialog(saving)}
                                  className="savings-confirm-button">
                                    PROCESS
                                </button>
                            </Link>
                        </li>
                        <li className="list-items-title" style={{fontSize: 9, width: 80, 
                             color:'#777', paddingLeft:10}}>
                            {moment(saving.start_date).format('DD MMM YYYY')} @ 
                            {moment(saving.start_date).format('hh:mm a')}
                        </li>
                        
                   </ul>
              )
          })

          const usersList = this.state.usersList.sort(function(x, y){
                    return new Date(y.start_date) - new Date(x.start_date);
                }).map(saving => {
                  return (
                    saving.status !== "Closing" &&
                    <ul key={saving._id} className="list-contents">
                        <li className="list-items">
                            {saving.account_number}
                        </li>
                        <li className="list-items" style={{paddingLeft:40}}>
                            #{saving.amount.toFixed(2)}
                        </li>
                        <li className="list-items" style={{paddingLeft:30, }}>
                            <span style={{
                                backgroundColor: 
                                    saving.status == "Active" ? "#daf7e1" : "#fdd2e3",
                                color: '#08227b',
                                padding: 10,
                                fontWeight: 600,
                                borderRadius: 5
                                }}>
                                {saving.status}
                            </span>
                        </li>
                        <li className="list-items">
                            #{this.calculateEarnings(saving)}
                        </li>
                        <li className="list-items" style={{width: 150}}>
                            {this.daysCount(saving)}
                        </li>
                        <li className="list-items">
                            <button style={{opacity: 0.1, cursor:'none'}}
                                className="savings-confirm-button">
                                CONFIRM
                            </button>
                        </li>
                        <li className="list-items-title" style={{fontSize: 9, width: 80, 
                             color:'#777', paddingLeft:10}}>
                            {moment(saving.start_date).format('DD MMM YYYY')} @ 
                            {moment(saving.start_date).format('hh:mm a')}
                        </li>
                        
                   </ul>
              )
          })  

        return (

            this.state.loading ? 
              <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                  <Spinner color="#1374ec" size={30}/> 
                  <p style={{color: '#1a2d6e', fontSize: 12, marginLeft: -17, marginTop: 12}}>
                      {this.state.loadLimit > 100 ? "Sorting datas..." : ''}</p> 
              </div>          
             :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Savings</p>
                </div> 
                    
                <div className="row-title" >
                    <p style={{fontWeight:500, color:'#101177'}}>All Savings</p>
                </div>

                <div className="list-container" >

                    <ul className="list-contents-title">
                        <li className="list-items-title">
                            Account No.
                        </li>
                        <li className="list-items-title" style={{paddingLeft:40}}>
                            Principal
                        </li>
                        <li className="list-items-title" style={{paddingLeft:30}}>
                            Status
                        </li>
                        <li className="list-items-title">
                            Earnings
                        </li>
                        <li className="list-items-title">
                            Days Count
                        </li>
                        <li className="list-items-title">
                            View Details
                        </li>
                        <li className="list-items-title" style={{width: 80}}>
                            Time
                        </li>
                       
                    </ul>
                     
                     {this.state.loading ?
                        <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                          <Spinner color="#1374ec" size={20}/>   
                        </div> 
                        :
                        <div>
                            {closingSavings}
                            {usersList}
                        </div>
                     }
                    
                </div>

                {isDialogOpen &&
                  <div className="dialog-box-container">
                     <div className="dialog-box-header">
                       <h3>{"SUPER SAVER WITHDRAWAL"}</h3>   
                        <img src="/close-icon.png" onClick={this.handleClose} />
                      </div>                             
                        {isLoadingEvent ? 
                            <div style={{display: "flex", marginTop:80}}>
                              <Spinner color="#47c494" size={20}/>   
                            </div> 
                            :
                        isSuccess ? 
                        <p style={{fontSize: 13, color: 'blue', width: "80%", marginTop:90, textAlign: 'center'}}>
                            {"SUCCESSFULLY PROCESSED"}
                        </p>
                        :
                        <p style={{fontSize: 13, color: '#333', width: "80%", marginTop:100}}>
                            You are about to confirm withdrawal for account No 
                            <em style={{fontWeight: 'bold', fontSize: 15, color: 'blueviolet'}}>{" " + savingData.account_number + " "}</em>.
                            Amount: <span style={{fontWeight: 'bold', fontSize: 20, color: '#6ad291'}}>{" #" + savingData.amount.toFixed(2) + " "}</span> .
                        </p>
                        }
                        <ButtonCustom 
                            text={"PROCEED"}
                            bgColor= "red"
                            opacity={0.5}
                            color="white"
                            onClick={() => this.processSavingsWithdraw(savingData)}
                            marginTop={60}
                        />
                </div>

               }

            </div>
               
        )
    }

}
