import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {API_URL} from '../../config/baseUrl';
import axios from 'axios';
import './SideBar.css';

export default class SideBar extends Component {

  logOut = () => {
    localStorage.clear();
    window.location.reload();
  }

  state = {
    userStatusProcessing: 0,
    userStatusApproved: 0,
    saversProcessing: 0,
    loading: true,
  }

  componentWillMount(){
    this.getUserData()
  }

  getUserData = async () => {
    const USER_TOKEN = localStorage.getItem('token');
    const AuthStr = 'Bearer '.concat(USER_TOKEN); 
    try {
        const response = await axios.get(API_URL + '/admin/get-user-status-processing', { headers: { Authorization: AuthStr } });
        const responseApproved = await axios.get(API_URL + '/admin/get-user-status-approved', { headers: { Authorization: AuthStr } });
        const resSavers = await axios.get(API_URL + '/admin/get-all-savings-data', { params: {limit: 20}, headers: { Authorization: AuthStr } });
        const saversProcessing = resSavers.data.savingsArray.filter(saver => saver.status == "Closing");
       // console.log("status length", userStatusApproved.length, userStatusProcessing.length)
        this.setState({
          userStatusApproved: responseApproved.data.users.length, 
          userStatusProcessing: response.data.users.length,
          saversProcessing: saversProcessing.length,
          loading: false
        })
    } catch (error) {
        //this.logOut()
        console.log('error ' + error);
    }
   
}

  render() {
        return (
            <div className="sidebar-wrapper">
               <div className="menu-wrapper">
                    <Link to="/">
                      <button className="menu-item">
                        Dashboard
                      </button>
                    </Link>
                    <Link to="/transactions">
                      <button className="menu-item">
                        Transactions
                      </button>
                    </Link>
                    <Link to="/verifications">
                      {this.state.loading && <p class="animated-gradient"></p>}
                      <button className="menu-item ">
                        Verifications
                        {!this.state.loading && <p className="menu-item-counter">{this.state.userStatusProcessing}</p>}
                      </button>
                    </Link>
                    <Link to="/approved">
                    {this.state.loading && <p class="animated-gradient"></p>}
                      <button className="menu-item">
                        Approved
                        {!this.state.loading && <p className="menu-item-counter" style={{backgroundColor:'#66a9e9'}}>
                           {this.state.userStatusApproved}</p>}
                      </button>
                    </Link>
                    <Link to="/analytics">
                      <button className="menu-item analytics-menu-btn">
                        Analytics
                      </button>
                    </Link>
                    <Link to="/Refferals">
                      <button className="menu-item">
                        Referals Data
                      </button>
                    </Link>

                    <Link to="/savings">
                     {this.state.loading && <p class="animated-gradient"></p>}
                      <button className="menu-item savings-menu-btn">
                        SuperSavers
                        {!this.state.loading && <p className="menu-item-counter" 
                        style={{backgroundColor:'#a3ffef', color: '#17133B', border: "5px solid #D6E9E5"}}>
                        {this.state.saversProcessing}</p>}
                      </button>
                    </Link>

                    <Link to="/email-datas">
                      <p className="menu-item email-menu-btn">
                        <span>Email Datas</span>
                        <img src="/email-icon.png" />
                      </p>

                    </Link>

                    <div className="menu-bottom-div">
                        <button onClick={this.logOut} className="menu-item">
                           Logout
                        </button>
                    </div>
               </div>
               
            </div>
        )
    }

}
