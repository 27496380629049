import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import {API_URL} from '../../config/baseUrl';
import axios from 'axios';
import Login from '../Login/Login'

import './ApprovedAccounts.css';

export default class ApprovedAccounts extends Component {

    state = {
       loading: true,
       allUsersList: [],
       usersList: [],
       queryString: '',
       isLoggedIn: true,
       usersCount: 0,
       transactionsCount: 0,
       verifiedUserCount: 0
    }

    componentWillMount(){
        
        const USER_TOKEN = localStorage.getItem('token');
        //.log('get token', USER_TOKEN)
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        
        //GET ALL USERS DATAS
        this.getUserData();

        //GET TRANSACTIONS COUNTS
        axios.get(API_URL + '/admin/get-all-transactions', { headers: { Authorization: AuthStr } })
        .then(response => {
            ////.log(response.data);
            this.setState({transactionsCount: response.data.transactions.length})
        })
        .catch((error) => {
            this.logOut()
            //.log('error ' + error);
        });
    }


    getUserData = async () => {
        const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        const data = {
            status: "approved"
        }
        try {
            const response = await axios.post(API_URL + '/admin/users-account-status', data, { headers: { Authorization: AuthStr } });
            //.log("verify 2")
            this.setState({
                usersList: response.data.users, 
                allUsersList: response.data.users, 
                loading:false, 
                usersCount: response.data.users.length,          
            })
        } catch (error) {
            this.logOut()
            //.log('error ' + error);
        }
       
    }

    searchUserList = async () => {
       const {queryString} = this.state;
        if (queryString != "") {
            const filteredList = []
            await this.state.allUsersList.map(user => {
            if (queryString == user.account_number || queryString == user._id) {
                filteredList.push(user)
             }
           })
          this.setState({usersList: filteredList})
        }

    }

    handleChange = (event) => {
        this.setState({queryString: event.target.value});
        if (event.target.value == '') {
            this.setState({usersList: this.state.allUsersList});
        }
      }

      logOut = () => {
        localStorage.clear();
        window.location.reload();
      }

    render() {
          
          const usersList = this.state.usersList.map(user => {
              return (
                    <ul key={user._id} className="list-contents">
                        <li className="list-items">
                            {user.fullname == null ? "Anonymouse" : user.fullname}
                        </li>
                        <li className="list-items">
                            {user.account_number}
                        </li>
                        <li className="list-items">
                            {user.blocked ? "Blocked" : "Active"}
                        </li>
                        <li className="list-items">
                            {user.verification_status}
                        </li>
                        <li className="list-items">
                            #{user.balance.toFixed(2)}
                        </li>
                        <li className="list-items">
                        <Link to={"/verify/" + user._id}>
                             <button className="trans-view-button">
                                View
                             </button>
                        </Link>
                        </li>
                   </ul>
              )
          }) 

        return (

            this.state.loading ? 
              <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                  <Spinner color="#47c494" size={30}/>   
              </div>          
             :
            <div className="main-content-wrapper">

                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Approved Accounts</p>
                </div>

                <div className="stat-boxes-wrap">
                   
                </div>   
                    
                <div className="row-title" >
                    <p style={{fontWeight:500}}>All customers</p>
                </div>

                <div className="list-container" >

                    <ul className="list-contents-title">
                        <li className="list-items-title">
                            Fullname
                        </li>
                        <li className="list-items-title">
                            Account number
                        </li>
                        <li className="list-items-title">
                            Status
                        </li>
                        <li className="list-items-title">
                            Verification status
                        </li>
                        <li className="list-items-title">
                            Balance
                        </li>
                        <li className="list-items-title">
                            View Details
                        </li>
                    </ul>
                     
                     {usersList}
                    
                </div>

            </div>
               
        )
    }

}
