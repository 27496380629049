import React, {Component} from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import CurrencyFormat from 'react-currency-format';
import {API_URL} from '../../config/baseUrl';
import axios from 'axios';
import moment from 'moment';
import { countries } from '../../config/countries';

import './UserDetail.css';

export default class UserDetail extends Component {

   state = {
      userData: {},
      loading: true,
      isDialogOpen: false,
      dialogKey: '',
      colorBG: '',
      amount: 0,
      selectedOption: null,
      isRejection: false,
      isSuccess: false,
      isLoadingEvent: false,
      isBlock: false,
      isUnblock: false
   }

   componentDidMount(){
       //.log(this.props.match.params.id)
      const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        let config = {
         headers: { Authorization: AuthStr },
         params: {
           id: this.props.match.params.id
         },
       }
       
        axios.get(API_URL + '/admin/retrieve-user-details', config)
        .then(response => {
            if (response.data.message === "success") {
               //.log(response.data);
               this.setState({userData: response.data.userData, loading:false})
            }

            else if(response.data.message == "blocked") {
                this.logOut()
            }
        })
        .catch((error) => {
            //.log('error ' + error);
        });
   }


   updateUserAccount = (dialogKey) => {
      this.setState({isLoadingEvent:  true})
      const {amount} = this.state;
      const USER_TOKEN = localStorage.getItem('token');
      var config = {
         headers: {'Authorization': "bearer " + USER_TOKEN}
      };
      
      var bodyParameters = {
         id: this.props.match.params.id,
         queryType: dialogKey,
         amount,
      }
      
      axios.post(API_URL +  
         '/admin/update-user-account',
         bodyParameters,
         config
         ).then((response) => {
            if (response.data.message === "success") {
                ////.log(response.data);
               // window.location.reload();
               this.setState({isSuccess: true, isLoadingEvent: false});
               setTimeout(() => {
                  window.location.reload();
               }, 1000);
            }

            else if (response.data.message === "blocked") {
                this.logOut()
            }
            else this.setState({isSuccess: false, isLoadingEvent: false})
         }).catch((error) => {
            //.log(error)
            alert("network error");
            this.setState({isSuccess: false, isLoadingEvent: false})
      });
     
   }

   logOut = () => {
      localStorage.clear();
       window.location.reload();
    }


   onChange = (e) => {
      this.setState({[e.target.name]: e.target.value});
       ////.log(e.target.name, e.target.value)
    }

   openDialog = (isBlock) => {
      this.setState({ isDialogOpen: true, isBlock})
   }
 
  handleClose = () => this.setState({ isDialogOpen: false })

  getUserCountryData = (telCode) => {
     const countryData =  countries.find(data => data.telCode == telCode);
     //console.log(countryData)
     return countryData;
  }

    render() {
        const {userData, dialogKey, loading, isDialogOpen, colorBG, isRejection, isSuccess, isLoadingEvent, isBlock} = this.state;
        const blockStatus =  userData.blocked ? "Unblock" : "Block";
        const suspendStatus =  userData.suspended ? "Unsuspend" : "Suspend";

        return (
            loading ? 
            <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
               <Spinner color="#47c494" size={30}/>   
            </div>          
            :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>User details</p>
                    <div className="call-to-actions-wrapper">
                         {userData.suspended ?
                           <ButtonCustom 
                              text="Unsuspend"
                              bgColor= "blue"
                              opacity={0.4}
                              onClick={() => this.openDialog(false)}
                              width={120}
                          /> :

                          <ButtonCustom 
                              text="Suspend"
                              bgColor= "blue"
                              opacity={0.6}
                              onClick={() => this.openDialog(false)}
                              width={120}
                              />
                         }
                         {userData.blocked ?
                              <ButtonCustom 
                              text="Unblock"
                              bgColor= "#f90732"
                              opacity={0.4}
                              onClick={() => this.openDialog(true)}
                              width={120}
                              /> :
                         <ButtonCustom 
                              text="Block"
                              bgColor= "#f90732"
                              opacity={0.7}
                              onClick={() => this.openDialog(true)}
                              width={120}
                              />
                         }
                    </div>
                </div>

                <div className="details-container">

                  {isDialogOpen ?
                     isBlock ?
                     <div className="dialog-box-container">
                        <div className="dialog-box-header">
                          <h3>{userData.blocked ? "UNBLOCK ACCOUNT" : "BLOCK ACCOUNT"}</h3>  
                          <img src="/close-icon.png" onClick={this.handleClose} />
                        </div>           
                           {isLoadingEvent ? 
                              <div style={{display: "flex", marginTop:80}}>
                                <Spinner color="#47c494" size={20}/>   
                               </div> 
                              :
                            isSuccess ? 
                            <p style={{fontSize: 13, color: 'red', width: "80%", marginTop:90, textAlign: 'center'}}>
                              {userData.blocked ? "SUCCESSFULLY UNBLOCKED!" : "SUCCESSFULLY BLOCKED!"}
                            </p>
                            :
                         <p style={{fontSize: 13, marginTop: 100, color: '#333', width: "80%"}}>
                           You are about to {userData.blocked ? "unblock" : "block"} this user account No 
                             <em style={{fontWeight: 'bold', fontSize: 15, color: 'blueviolet'}}>{" " + userData.account_number + " "}</em>. 
                             Kindly make sure this action is justified.</p>
                           }
                           <ButtonCustom 
                              text="PROCCEED"
                              bgColor= "red"
                              opacity={1}
                              color="white"
                              onClick={() => this.updateUserAccount(blockStatus)}
                              marginTop={40}
                           />
                      </div>
                      :
                      <div className="dialog-box-container">
                        <div className="dialog-box-header">
                        <h3>{userData.suspended ? "UNSUSPEND ACCOUNT" : "SUSPEND ACCOUNT"}</h3>   
                          <img src="/close-icon.png" onClick={this.handleClose} />
                        </div>                             
                           {isLoadingEvent ? 
                              <div style={{display: "flex", marginTop:80}}>
                                <Spinner color="#47c494" size={20}/>   
                               </div> 
                              :
                            isSuccess ? 
                            <p style={{fontSize: 13, color: 'blue', width: "80%", marginTop:90, textAlign: 'center'}}>
                               {userData.suspended ? "SUCCESSFULLY UNSUSPEND!" : "SUCCESSFULLY SUSPEND!"}
                            </p>
                            :
                            <p style={{fontSize: 13, color: '#333', width: "80%", marginTop:100}}>
                              You are about to {userData.suspended ? "unsuspend" : "suspend"} this user account No 
                              <em style={{fontWeight: 'bold', fontSize: 15, color: 'blueviolet'}}>{" " + userData.account_number + " "}</em>. Kindly make sure this action is justified.
                           </p>
                           }
                           <ButtonCustom 
                              text={"PROCEED"}
                              bgColor= "red"
                              opacity={0.5}
                              color="white"
                              onClick={() => this.updateUserAccount(suspendStatus)}
                              marginTop={60}

                           />
                    </div>:''

                   }
                   <div className="user-details-wrapper">
                        <div className="user-data-info">
                           <p className="info-title">Fullname</p>
                           <p className="info-response">{userData == null ? "Anonymouse" : userData.fullname}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Account No.</p>
                            <img style={{objectFit: 'conver', width:25, borderRadius: 2, marginRight: 5, opacity: 0.7}}
                              src={"/flags/" + this.getUserCountryData(userData.userCountryCode).code + ".jpg"} />
                           <p className="info-response">{userData.account_number}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Registered In</p>
                           <p className="info-response">{this.getUserCountryData(userData.userCountryCode).name}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Credit Score</p>
                           <p className="info-response">{userData.creditScorePoint}%</p>
                        </div>
                        { userData.loanFile &&
                           <div className="user-data-info">
                           <p className="info-title">Debt</p>
                           <p style={{fontWeight: 'bold', color:'red'}} className="info-response">
                           <CurrencyFormat value={userData.loanFile.debt} displayType={'text'} thousandSeparator={true} prefix={'-N'} /></p>
                           
                        </div>}

                        <div className="user-data-info">
                           <p className="info-title">Email</p>
                           <p className="info-response">{userData.email}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Balance</p>
                           <CurrencyFormat value={userData.balance.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Account status</p>
                           <p className="info-response">{userData.blocked ? "Blocked" : "Active"}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Reward</p>
                            {userData.reward &&
                             <p className="info-response">{userData.reward}</p>
                            }
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Verification Status</p>
                           <p className="info-response">{userData.verification_status}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Address</p>
                           {userData.address != undefined ?
                             <div className="info-response">
                             <p className="info-title">{userData.address.street}</p>
                             <p className="info-title">{userData.address.city}</p><br></br>
                             <p className="info-title">{userData.address.state}</p>
                            </div>
                            :
                            <p className="info-title">-------</p>
                           }
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Transactions count</p>
                           <p className="info-response">{" Transfers = " + userData.transfers.length} {"  Topups = " +  userData.topup.length}  {"  Withdrawals = " + userData.withdrawals.length} {"  Bills Payments = " + userData.billPayment.length}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">BVN</p>
                            {userData.BVN ? 
                             <p className="info-response">{'submitted'}</p>
                             :
                             <p className="info-response">{'Not submitted'}</p>
                            }
                        </div>

                        {userData.accountOfficerData &&
                          userData.accountOfficerData.rejectedBy &&
                           <div className="user-data-info">
                              <p className="info-title">Rejected By</p>
                              {userData.accountOfficerData.rejectedBy &&
                              <p style={{color:'#CA4063'}}
                                className="info-response">{userData.accountOfficerData.rejectedBy }</p>
                              }
                           </div>
                        }
                        
                        {userData.accountOfficerData &&
                           <div className="user-data-info">
                              <p className="info-title">Approved By</p>
                              {userData.accountOfficerData.approvedBy &&
                              <p style={{color:'#325739'}}
                                className="info-response">{userData.accountOfficerData.approvedBy }</p>
                              }
                           </div>
                        }

                        {userData.accountOfficerData &&
                           <div className="user-data-info">
                              <p className="info-title">Verified By</p>
                              {userData.accountOfficerData.verifiedBy &&
                              <p style={{color:'#274E79'}}
                                className="info-response">{userData.accountOfficerData.verifiedBy }</p>
                              }
                           </div>
                        }

                        <div className="user-data-info">
                           <p className="info-title">Date Of Birth</p>
                            {userData.BVN &&
                             <p className="info-response">{userData.BVN.formatted_dob}</p>
                            }
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Transfer Limit</p>
                           <CurrencyFormat value={userData.transfer_limit} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Withdrawal Limit</p>
                           <CurrencyFormat value={userData.withdrawal_limit} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Date Registered</p>
                           <p className="info-title">{moment(userData.date_created).format('DD MMM YYYY')}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Bank accounts</p>
                           <div>
                            {userData.bank_accounts.map(bank => {
                               return (
                                 <div key={bank._id} className="info-response">
                                    <p className="info-title">{bank.bank_name}</p>
                                    <p className="info-title">{bank.bank_account_number}</p><br></br>
                                 </div>
                               )
                            })}
                            </div>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Cards</p>
                           <div>
                            {userData.cards.map(card => {
                               return (
                                 <div key={card._id} className="info-response">
                                    <p className="info-title">{card.card_type}</p>
                                    <p className="info-title">{"**** **** **** " + card.last_four_digit}</p><br></br>
                                 </div>
                               )
                            })}
                            </div>
                        </div>             

                   </div>

                   <div className="user-identity-card-wrapper">
                         { userData.identify_copy_img ? 
                            <img style={{objectFit: 'contain', width:'90%'}}
                              src={"https://dodo-tech-id.s3.us-east-2.amazonaws.com/" + userData.identify_copy_img} />
                            :
                            <img style={{objectFit: 'conver', opacity:0.3}}
                              src="/user-id.jpg" />
                         }
                   </div>
                </div>

                
            </div>
               
        )
    }

}
