import React, {Component} from 'react';
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import {API_URL} from '../../config/baseUrl';
import axios from 'axios';

import './Login.css';

export default class Login extends Component {

  state = {
    email: '',
    password: '',
    loading: false,
    otp: '',
    serverOtp: null,
    showOtpInput: false,
    isloading: false,
    userData: {}
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
     //.log(e.target.name, e.target.value)
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true})
    axios.post(API_URL + '/admin/login-admin', {
      email: this.state.email,
      password: this.state.password
     }
    )
    .then(response => {
      if (response.data.message === "success") {
          //.log(response.data.userData)
          this.setState({
            showOtpInput: true, 
            loading: false, 
            serverOtp: response.data.otp,
            userData: response.data.userData,
            email: ''
          })
      }

      else {
        alert("login access error")
        console.log(response.data.message)
      }
    })
    .catch(error => {
      alert("network error")
      console.log(error)
    })
  }

  LoginFunc = (e) => {
    e.preventDefault();
    if (this.state.otp == this.state.serverOtp) {
        //.log(this.state.userData)
        localStorage.setItem('userData', JSON.stringify(this.state.userData));
        localStorage.setItem('token', this.state.userData.token);
         window.location.reload();
    }

    else {
      alert("enter correct otp")
    }
  }

  resendOtp = () => {
    this.setState({showOtpInput: false})
  }

    render() {
        return (
               <div className="login-page-container">
                 
                  <div className="logo-div">
                    <img className="logo-image" src="./dodo-blue.png" />
                  </div>
 
                   {this.state.showOtpInput ? 
                     <form onSubmit={this.LoginFunc} style={{paddingBottom:35}}
                     className="login-form-wrapper">
                     <input onChange={this.onChange} placeholder="OTP" type="text" name="otp" required value={this.state.otp}/>

                   {this.state.loading ? 
                      <button style={{background: '#1352F3'}}  disabled={true}>
                         <Spinner color="#fff"/>
                      </button> 
                      :
                      <button style={{background: '#1352F3'}}  type="submit">SUBMIT</button>
                     }
                     <p onClick={this.resendOtp}
                        style={{fontWeight: 500, cursor: 'pointer'}} >resend otp</p>
                     </form>
                     :
                     <form onSubmit={this.onSubmit} style={{paddingBottom:35}}
                      className="login-form-wrapper">
                      <input style={{marginTop: 30}}
                         onChange={this.onChange} placeholder="Email" type="Email" name="email" required/>
                      <input onChange={this.onChange} placeholder="Password" type="Password" name="password"  required />

                       {this.state.loading ? 
                       <button style={{border: 'none',background: '#1352F3'}} disabled={true}>
                          <Spinner color="#fff"/>
                       </button> 
                       :
                       <button style={{background: '#1352F3'}}  type="submit">LOGIN</button>
                      }
                    </form>
                   }

               </div>
        )
    }

}
