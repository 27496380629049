import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import axios from 'axios';
import Login from '../Login/Login';
import {API_URL} from '../../config/baseUrl';
import CustomSelect from '../CustomSelect/CustomSelect';
import moment from 'moment';

import './Refferals.css';


var REFERENCE = moment();
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

export default class Refferals extends Component {

    state = {
       loading: true,
       allUsersList: [],
       usersList: [],
       queryString: '',
       isLoggedIn: true,
       usersCount: 0,
       transactionsCount: 0,
       verifiedUserCount: 0,
       searchLoading: false,
       isOpen: false,
       selectedData: {value: 'allTime', label: "All time users"},
       loadLimit: 5000,
    }

    componentWillMount(){   
        const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 

        //GET ALL USERS DATAS
        this.getUserData();
    }

    getUserData = async () => {
        const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        try {
            const response = await axios.get(API_URL + '/admin/get-all-user-accounts', { params: {
                 limit: this.state.loadLimit
              }, headers: { Authorization: AuthStr } });
            //console.log('users', response.data.users)
            const sortedByRefs = await response.data.users.sort((a, b) => b.referrals.length - a.referrals.length);
                 console.log('sortedByRefs', sortedByRefs.length)
            this.setState({
                usersList: sortedByRefs, 
                allUsersList: sortedByRefs, 
                loading:false, 
            })
        } catch (error) {
            //this.logOut()
            console.log('error ' + error);
        }
       
    }


    handleChange = (event) => {
        this.setState({queryString: event.target.value});
        if (event.target.value == '') {
            this.setState({usersList: this.state.allUsersList});
        }
      }

      logOut = () => {
        localStorage.clear();
         window.location.reload();
      }

    
    isToday = (momentDate) => {
        //console.log(momentDate)
        return moment().isSame(momentDate, 'day'); 
    }

    isYesterday = (momentDate) => {
        //console.log(moment(momentDate).subtract(1, 'days').startOf('day'))
        return moment(momentDate).subtract(2, 'days') && moment(momentDate).isBefore(moment(), 'day');
    }
  
    isWeek= (momentDate) => {
     //console.log(momentDate)
     return moment().isSame(momentDate, 'week'); 
    }

    isMonth= (momentDate) => {
        //console.log(momentDate)
        return moment().isSame(momentDate, 'month'); 
       }

    render() {
          
          const usersList = this.state.usersList.slice(0, 100).map(user => {
              return (
                    <ul key={user._id} className="list-contents">
                        <li className="list-items">
                            {user.fullname == null ? "Anonymouse" : user.fullname}
                        </li>
                        <li className="list-items" style={{paddingLeft:40}}>
                            {user.account_number}
                        </li>
                        <li className="list-items" style={{paddingLeft:30}}>
                            {user.blocked ? "Blocked" : "Active"}
                        </li>
                        <li className="list-items" style={{fontWeight: 700, color: 'green'}}>
                            {user.reward.toFixed(2)}
                        </li>
                        <li className="list-items">
                            #{user.balance.toFixed(2)}
                        </li>
                        <li className="list-items" style={{fontWeight: 700, color:'blue'}}>
                             {user.referrals.length}
                        </li>
                        <li className="list-items-title" style={{fontSize: 9, width: 80, color:'#777'
                             }}>
                            #{user.ref_code}
                        </li>
                   </ul>
              )
          }) 

        return (

            this.state.loading ? 
              <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                  <Spinner color="#47c494" size={30}/> 
                  <p style={{color: '#1a2d6e', fontSize: 12, marginLeft: -17, marginTop: 12}}>
                      {this.state.loadLimit > 100 ? "Sorting datas..." : ''}</p> 
              </div>          
             :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Refferals</p>
                </div> 
                    
                <div className="row-title" >
                    <p style={{fontWeight:500, color:'#101177'}}>Users Refferals Data</p>
                </div>

                <div className="list-container" >

                    <ul className="list-contents-title">
                        <li className="list-items-title">
                            Fullname
                        </li>
                        <li className="list-items-title" style={{paddingLeft:40}}>
                            Account No.
                        </li>
                        <li className="list-items-title" style={{paddingLeft:30}}>
                            Status
                        </li>
                        <li className="list-items-title">
                            Rewards
                        </li>
                        <li className="list-items-title">
                            Balance
                        </li>
                        <li className="list-items-title">
                            No. Referals
                        </li>
                        <li className="list-items-title" style={{width: 80, color:'#fff'}}>
                            Code
                        </li>
                    </ul>
                     
                     {this.state.searchLoading ?
                        <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                          <Spinner color="#47c494" size={20}/>   
                        </div> 
                        :
                       usersList
                     }
                    
                </div>

            </div>
               
        )
    }

}
