import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import axios from 'axios';
import Login from '../Login/Login';
import {API_URL} from '../../config/baseUrl';
import CustomSelect from '../CustomSelect/CustomSelect';
import moment from 'moment';
import { countries } from '../../config/countries';

import './Dashboard.css';

const transFilterData = [
    {value: 'allTime', label: "All time users"},
    {value: 'today', label: "Today signups"},
    {value: 'isYesterday', label: "Yesterday's Signups"},
    {value: '7days', label: "Last 1 week"}
]

var REFERENCE = moment();
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

export default class Dashboard extends Component {

    state = {
       loading: true,
       allUsersList: [],
       usersList: [],
       queryString: '',
       isLoggedIn: true,
       usersCount: 0,
       transactionsCount: 0,
       verifiedUserCount: 0,
       searchLoading: false,
       isOpen: false,
       selectedData: {value: 'allTime', label: "All time users"},
       loadLimit: 50,
    }

    componentWillMount(){   
        const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 

        //GET ALL USERS DATAS
        this.getUserData();

        //GET TRANSACTIONS COUNTS
        axios.get(API_URL + '/admin/get-all-transactions', { headers: { Authorization: AuthStr } })
        .then(response => {
            ////.log(response.data);
            this.setState({transactionsCount: response.data.transactions.length})
        })
        .catch((error) => {
            this.logOut();
            //.log('error ' + error);
        });
    }

    getUserData = async () => {
        const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        try {
            const response = await axios.get(API_URL + '/admin/get-all-user-accounts', { params: {
                 limit: this.state.loadLimit
              }, headers: { Authorization: AuthStr } });
            //console.log('users', response.data.users)
            const verifiedUserCount = await response.data.users.filter(user => user.verified);
            this.setState({
                usersList: response.data.users, 
                allUsersList: response.data.users, 
                loading:false, 
                usersCount: response.data.users.length,
                verifiedUserCount: verifiedUserCount.length
            })
        } catch (error) {
            //this.logOut()
            console.log('error ' + error);
        }
       
    }

    findUserData = async () => {
        this.setState({searchLoading: true})
        const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        const {queryString} = this.state;
        const data  = {
            queryString,
        }
        try {
            const response = await axios.post(API_URL + '/admin/find-user-data', data, {params: {
                limit: this.state.loadLimit
             }, headers: { Authorization: AuthStr } });
             if(response.data.message == "success")
             this.setState({usersList: response.data.user, searchLoading: false});
             else this.setState({searchLoading: false, usersList: []})
        } catch (error) {
            //.log('error ' + error);
            this.setState({searchLoading: false, usersList: []})
        }
       
    }


    searchUserList = async () => {
       const {queryString} = this.state;
        if (queryString != "") {
            const filteredList = []
            await this.state.allUsersList.map(user => {
            if (queryString == user.account_number || queryString == user._id) {
                filteredList.push(user)
             }
           })
          this.setState({usersList: filteredList})
        }

    }

    handleChange = (event) => {
        this.setState({queryString: event.target.value});
        if (event.target.value == '') {
            this.setState({usersList: this.state.allUsersList});
        }
      }

      logOut = () => {
        localStorage.clear();
         window.location.reload();
      }

      showIncomingFunc = (trans) => {
        if(trans.transaction_type == "transfer" &&  trans.transfer_type == "IN-COMING") return false;
        else return true;
    }
    
    isOpenFunc = () => {
        this.setState({isOpen: !this.state.isOpen})
    }
    
    onSelectFunc = (item) => {
        this.setState({selectedData: item, isOpen: false, loading: true}, () => {
            this.filterUserByDate(item.value)
        })
    }

    filterUserByDate = async (period) => {
        const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        try {
            this.setState({loadLimit: 100})
            const response = await axios.get(API_URL + '/admin/get-all-user-accounts', { headers: { Authorization: AuthStr } });
            const users = await response.data.users.filter(user => 
                period == "today" ? this.isToday(user.date_created) : 
                period == "allTime" ? this.isMonth(user.date_created) :
                period == "isYesterday" ? this.isYesterday(user.date_created) : 
                this.isWeek(user.date_created)
            );
            this.setState({
                usersList: users, 
                allUsersList: users, 
                loading: false
            })
        } catch (error) {
           // this.logOut()
            console.log('error ' + error);
        }
       
    }
    
    isToday = (momentDate) => {
        //console.log(momentDate)
        return moment().isSame(momentDate, 'day'); 
    }

    isYesterday = (momentDate) => {
        //console.log(moment(momentDate).subtract(1, 'days').startOf('day'))
        return moment(momentDate).subtract(2, 'days') && moment(momentDate).isBefore(moment(), 'day');
    }
  
    isWeek= (momentDate) => {
     //console.log(momentDate)
     return moment(momentDate).isBefore(moment(), 'week');
    }

    isMonth= (momentDate) => {
        //console.log(momentDate)
        return moment(momentDate).isBefore(moment(), 'month');
    }

    getUserCountryData = (telCode) => {
     const countryData =  countries.find(data => data.telCode == telCode);
     //console.log(countryData)
     return countryData;
  }

    render() {
          
          const usersList = this.state.usersList.map(user => {
              return (
                    <ul key={user._id} className="list-contents">
                        <li className="list-items">
                            {user.fullname == null ? "Anonymouse" : user.fullname}
                        </li>
                        <li className="list-items" style={{paddingLeft:40}}>
                            <img style={{objectFit: 'conver', width:25, borderRadius: 2, marginRight: 5, opacity: 0.7}}
                              src={"/flags/" + this.getUserCountryData(user.userCountryCode).code + ".jpg"} />
                            {user.account_number}
                        </li>
                        <li className="list-items" style={{paddingLeft:30}}>
                            {user.blocked ? "Blocked" : "Active"}
                        </li>
                        <li className="list-items">
                            {user.verification_status}
                        </li>
                        <li className="list-items">
                            #{user.balance.toFixed(2)}
                        </li>
                        <li className="list-items">
                        <Link to={"/user/" + user._id}>
                             <button className="trans-view-button">
                                View
                             </button>
                        </Link>
                        </li>
                        <li className="list-items-title" style={{fontSize: 9, width: 80,
                             color:'#777'}}>
                            {moment(user.date_created).format('DD MMM YYYY')} @ 
                            {moment(user.date_created).format('hh:mm a')}
                        </li>
                   </ul>
              )
          }) 

        return (

            this.state.loading ? 
              <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                  <Spinner color="#47c494" size={30}/> 
                  <p style={{color: '#1a2d6e', fontSize: 12, marginLeft: -17, marginTop: 12}}>
                      {this.state.loadLimit > 100 ? "Sorting datas..." : ''}</p> 
              </div>          
             :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Dashboard</p>
                </div> 
                    
                <div className="row-title" >
                    <p style={{fontWeight:500, color:'#101177'}}>All customers</p>
                    <div style={{marginRight: -150}}>
                       <CustomSelect 
                           isOpen={this.state.isOpen} 
                           onClick={this.isOpenFunc}  
                           selectedData={this.state.selectedData}
                           onSelect={this.onSelectFunc}
                           data={transFilterData}
                        />
                    </div>
                    <div className="search-input-wrapper">
                        <input type="text" name="queryString" value={this.state.queryString} placeholder="search" onChange={this.handleChange} />
                        <img src="/search-icon.png" onClick={this.findUserData} />
                    </div>
                </div>

                <div className="list-container" >

                    <ul className="list-contents-title">
                        <li className="list-items-title">
                            Fullname
                        </li>
                        <li className="list-items-title" style={{paddingLeft:40}}>
                            Account No.
                        </li>
                        <li className="list-items-title" style={{paddingLeft:30}}>
                            Status
                        </li>
                        <li className="list-items-title">
                            Verification
                        </li>
                        <li className="list-items-title">
                            Balance
                        </li>
                        <li className="list-items-title">
                            View Details
                        </li>
                        <li className="list-items-title" style={{width: 80}}>
                            Time
                        </li>
                    </ul>
                     
                     {this.state.searchLoading ?
                        <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                          <Spinner color="#47c494" size={20}/>   
                        </div> 
                        :
                       usersList
                     }
                    
                </div>

            </div>
               
        )
    }

}
